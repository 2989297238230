import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../utils/variables';
import { Button } from '@mui/material';

const LaunchApp = (props) => {
    return (
        <div className="launch_app">
            <div className="section1">
                {variables[props.lang]['launch_app_text']}
            </div>
            <div className="section2">
                <Button onClick={() => window.open('https://app.streamswap.io', '_self')}>
                    {variables[props.lang]['launch_app']}
                </Button>
            </div>
        </div>
    );
};

LaunchApp.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(LaunchApp);

const variables = {
    en: {
        launch_app_text: 'The future of community-driven token launches is here!',
        launch_app: 'Launch App',
        stream_token_launch: 'Get ready for $FLIX token launch',
        apply_stream_token: 'Apply to Stream your token using',
        apply: 'Apply',

        how_it_works: 'How it works?',
        stream_creation: 'Stream Creation',
        stream_creation_content: 'The streamer locks tokens, sets duration and treasury account to create the stream, and bootstrapping starts right away',
        bootstrapping_phase: 'Bootstrapping Phase',
        bootstrapping_phase_content: 'Subscribers can deposit and withdraw deposited tokens to receive shares for a fair start. No swaps occur, so users can join anytime during this phase',
        streaming_phase: 'Streaming Phase',
        streaming_phase_content: 'Tokens to stream and Tokens to subscribe get swapped while streaming at each other over the set duration of the Stream',
        redeem: 'Redeem',
        redeem_content: 'Subscribers can redeem their swapped tokens, at the end of the Stream after all streaming is completed',
        check_the_article: 'Check the full article here',

        footer_content: 'StreamSwap is a protocol for time-based token swaps, \n' +
            'a new DeFi primitive, for community-driven token launches on Osmosis within the Cosmos ecosystem',
        telegram: 'Telegram',
        twitter: 'Twitter',
    },
};

export default variables;

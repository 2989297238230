import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../utils/variables';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Button } from '@mui/material';
import { ReactComponent as TelegramIcon } from '../../assets/social/telegram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/social/twitter.svg';

const Footer = (props) => {
    return (
        <div className="footer">
            <div>
                <div className="left">
                    <Logo />
                    <p>{variables[props.lang]['footer_content']}</p>
                </div>
                <div className="right">
                    <Button onClick={() => window.open('https://t.me/StreamSwap_io')}>
                        <TelegramIcon />
                        {variables[props.lang].telegram}
                    </Button>
                    <Button onClick={() => window.open('https://twitter.com/StreamSwap_io')}>
                        <TwitterIcon />
                        {variables[props.lang].twitter}
                    </Button>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Footer);

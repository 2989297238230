import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../utils/variables';
import creationImage from '../../assets/stream-creation.svg';
import bootstrapImage from '../../assets/bootstrapping-phase.svg';
import streamingImage from '../../assets/streaming-phase.svg';
import redeemImage from '../../assets/redeem-phase.svg';
import { ReactComponent as BlueIcon } from './../../assets/blur/blue-2.svg';
import { ReactComponent as PinkIcon } from './../../assets/blur/pink-2.svg';

const HowWorks = (props) => {
    const list = [{
        name: variables[props.lang]['stream_creation'],
        content: variables[props.lang]['stream_creation_content'],
        image: creationImage,
    }, {
        name: variables[props.lang]['bootstrapping_phase'],
        content: variables[props.lang]['bootstrapping_phase_content'],
        image: bootstrapImage,
    }, {
        name: variables[props.lang]['streaming_phase'],
        content: variables[props.lang]['streaming_phase_content'],
        image: streamingImage,
    }, {
        name: variables[props.lang].redeem,
        content: variables[props.lang]['redeem_content'],
        image: redeemImage,
    }];

    return (
        <div className="how_works">
            <div className="section2_right">
                <PinkIcon />
            </div>
            <div className="section2_left">
                <BlueIcon />
            </div>
            <h2>{variables[props.lang]['how_it_works']}</h2>
            {list.map((item, index) => (
                <div key={index} className="section">
                    <div className="left">
                        <img alt={item.name} src={item.image} />
                    </div>
                    <div className="right">
                        <div className="name">
                            <div>
                                <span>{index + 1}</span>
                            </div>
                            <p>{item.name}</p>
                        </div>
                        <div className="content">
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

HowWorks.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(HowWorks);

import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import LaunchApp from './LaunchApp';
import variables from '../../utils/variables';
import HowWorks from './HowWorks';
import { Button } from '@mui/material';
import Footer from './Footer';
import { ReactComponent as BlueIcon } from './../../assets/blur/blue-1.svg';
import { ReactComponent as PinkIcon } from './../../assets/blur/pink-1.svg';

class Home extends React.Component {
    render () {
        return (
            <div className="home">
                <div className="section1_right">
                    <BlueIcon />
                </div>
                <div className="section1_left">
                    <PinkIcon />
                </div>
                <div
                    className="logo_section">
                    <Logo />
                </div>
                <LaunchApp />
                <div className="launch_date">
                    <div>
                        <p>
                            {variables[this.props.lang]['apply_stream_token']}
                            <Logo />
                        </p>
                        <Button onClick={() => window.open('https://twitter.com/StreamSwap_io')}>
                            <p>{variables[this.props.lang].apply}</p>
                        </Button>
                    </div>
                </div>
                <HowWorks />
                <div className="check_article">
                    <Button onClick={() => window.open('https://mirror.xyz/streamswapprotocol.eth/3-x3fWlrkoLfwM7lOTxDL1lboWJp9u3RzszHUHc0BPM')}>
                        {variables[this.props.lang]['check_the_article']}
                    </Button>
                </div>
                <Footer />
            </div>
        );
    }
}

Home.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {
};

export default connect(stateToProps, actionToProps)(Home);
